import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import Link, {LinkProps as NextLinkProps} from 'next/link';
import {ArrowForwardIcon, ExternalLinkIcon} from '@chakra-ui/icons';

export interface KhsLinkInternalProps {
  isExternal?: boolean;
  clickable?: boolean;
  href?: string;
  title: string;
}

export type KhsLinkProps = KhsLinkInternalProps &
  ChakraLinkProps &
  NextLinkProps;

export const KhsLink = ({
  isExternal = false,
  clickable = true,
  href,
  title,
  ...rest
}: KhsLinkProps) => {
  const RenderIcon = () => {
    return isExternal ? (
      <ExternalLinkIcon
        ml={1}
        w={4}
        h={4}
        sx={{
          position: 'relative',
          top: '-2px',
        }}
      />
    ) : (
      <ArrowForwardIcon
        w={4}
        h={4}
        ml={1}
        sx={{
          transition: 'all 0.3s ease',
          position: 'relative',
          top: '-2px',
          _groupHover: {
            transform: 'translateX(5px)',
          },
        }}
      />
    );
  };

  return (
    <Link href={href} passHref>
      <ChakraLink
        role="group"
        cursor={clickable ? 'pointer' : 'auto'}
        pointerEvents={clickable ? 'auto' : 'none'}
        lineHeight="24px"
        isExternal={isExternal}
        textDecoration="none"
        fontWeight="400"
        textColor="primaryLinkColor"
        {...rest}>
        {title}
        {RenderIcon()}
      </ChakraLink>
    </Link>
  );
};
