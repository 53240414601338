import {Icon, useTheme} from '@chakra-ui/react';
import {DefaultTheme} from '../../../../theme/themes/default';
import {Colors} from '../../../../theme/colors';

interface Props {
  width: string;
  height: string;
  color: keyof Colors;
}

export const ArrowRight = ({width, height, color}: Props) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <Icon
      viewBox={`0 0 ${width} ${height}`}
      width={`${width}px`}
      height={`${height}px`}
      color={theme.colors[color]}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.75 9.504h10.5M9 4.254l5.25 5.25L9 14.754"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
