import {Icon} from '@chakra-ui/react';
import {Colors} from '../../../../theme/colors';

interface Props {
  width: string;
  height: string;
  color: keyof Colors;
}
export const Info = ({width, height, color}: Props) => {
  return (
    <Icon
      viewBox={`0 0 24 24`}
      width={`${width}px`}
      height={`${height}px`}
      color={color}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <g
          clipPath="url(#clip0)"
          stroke="#003145"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M12.502 22c5.523 0 10-4.477 10-10s-4.477-10-10-10c-5.522 0-10 4.477-10 10s4.478 10 10 10zM12.502 16v-4M12.502 8h.01" />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" transform="translate(.502)" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};
