import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {languageSelector} from '../lib/context/selectors';

export function useLanguageChange(refetch: () => void) {
  const language = useSelector(languageSelector);
  useEffect(() => {
    refetch();
  }, [language, refetch]);

  return;
}
