import {useTheme} from '@chakra-ui/react';
import {DefaultTheme} from '../../../../theme/themes/default';
import {Colors} from '../../../../theme/colors';

interface Props {
  width: string;
  height: string;
  color: keyof Colors;
}

export const ArrowLeft = ({width, height, color}: Props) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 12H5"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19L5 12L12 5"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
