import {useSelector} from 'react-redux';
import {contextSelector} from '../lib/context/selectors';

export const useCustomerTag = () => {
  const context = useSelector(contextSelector);

  const customer = context.filter((c) => {
    return c.axis === 'environment' && c.label === 'customer';
  })[0];

  if (!customer) {
    throw new Error('Required customer not found in context');
  }

  return customer.value.toString().toLowerCase();
};
