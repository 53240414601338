import {Box, Text} from '@chakra-ui/react';
export interface FooterUspsProps {
  items: string[];
}

export const FooterUsps = ({items}: FooterUspsProps) => {
  return (
    <Box position="relative" mb={10} mt={9} data-cy="footerUsps">
      {items.map((item, index) => (
        <Box
          ml={6}
          mb={[0, index === items.length - 1 ? 0 : 6]}
          sx={{
            _before: {
              content: "''",
              position: 'absolute',
              borderColor: 'footerUspArrowColor',
              borderStyle: 'solid',
              borderWidth: '0 0.15em 0.15em 0',
              height: '0.9em',
              marginLeft: '-1.125em',
              marginTop: '0.25rem',
              transform: 'rotate(45deg)',
              width: '0.5em',
            },
          }}
          key={index}>
          <Text ml={2} mb={4}>
            {item}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
