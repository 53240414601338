import {ListItem, UnorderedList, Link as ChakraLink} from '@chakra-ui/react';
import Link from 'next/link';
import {Icon} from '../_shared/Icon';
import {FooterItem} from './../Footer/FooterView';

export interface FooterLinksProps {
  items: FooterItem[];
}

export const FooterLinks = ({items}: FooterLinksProps) => (
  <UnorderedList
    p="0"
    m="0"
    mt="12"
    mb={[-2, 12]}
    listStyleType="none"
    data-cy="footerLinks">
    {items.map((item, index) => {
      if (!item.url) {
        console.warn(
          `Footer link with title:${item.title} has no corresponding url defined`,
        );
      }
      return item.url ? (
        <ListItem
          display="flex"
          alignItems="center"
          pb={items.length - 1 === index ? 10 : 4}
          key={item.url + item.title}>
          <Link passHref href={item.url ? item.url : ''}>
            <ChakraLink textStyle="link" pr="1" color="footerLinkColor">
              {item.title}
            </ChakraLink>
          </Link>
          <Icon
            name="arrow_right"
            color="footerLinkArrowColor"
            width="18"
            height="18"
          />
        </ListItem>
      ) : null;
    })}
  </UnorderedList>
);
