import {
  SimpleGrid,
  Box,
  Text,
  Image,
  Link as ChakraLink,
  Heading,
} from '@chakra-ui/react';
import Link from 'next/link';
import React, {Fragment} from 'react';
import {AppButton} from '../AppButton';
import {FooterUsps} from '../FooterUsps';
import {Main} from '../_shared/Layout/Main';
import {FooterLinks} from '../FooterLinks';

export type FooterItem = {
  title: string;
  url: string | null;
};

export interface FooterProps {
  title: string;
  subTitle: string;
  image?: string;
  usps: string[];
  appUrls: {ios: string; android: string};
  contact: FooterItem[];
  links: FooterItem[];
  lazyLoading: boolean;
  copyText?: string;
  minimalistic: boolean;
}

export const FooterView = ({
  title,
  subTitle,
  image,
  usps,
  appUrls,
  contact,
  links,
  lazyLoading,
  copyText,
  minimalistic,
}: FooterProps) => {
  return (
    <Box data-cy="footer">
      {!minimalistic && (
        <Box position="relative" color="footerColor">
          <Main>
            <Box>
              <SimpleGrid columns={{sm: 1, md: 2}}>
                <Box>
                  <Heading mb="8" mt="8">
                    {title}
                  </Heading>
                  <Text as="span" fontWeight="normal">
                    {subTitle}
                  </Text>
                  {usps && <FooterUsps items={usps} />}
                  {appUrls.ios && (
                    <AppButton
                      lazyLoading={lazyLoading}
                      store="ios"
                      url={appUrls.ios}
                      data-cy="iosButton"
                    />
                  )}
                  {appUrls.android && (
                    <AppButton
                      lazyLoading={lazyLoading}
                      store="android"
                      url={appUrls.android}
                      data-cy="androidButton"
                    />
                  )}

                  {contact && <FooterLinks items={contact} />}
                </Box>
              </SimpleGrid>
            </Box>
            <Box
              sx={{
                position: ['static', 'absolute'],
                width: ['auto', '55%'],
                height: '100%',
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'flex-end',
                marginLeft: ['-8', 0],
                marginRight: ['-8', 0],
              }}>
              <Image
                loading={lazyLoading ? 'lazy' : 'eager'}
                alt=""
                src={image}
                data-cy="footerImage"
                sx={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>
          </Main>
        </Box>
      )}
      <Box backgroundColor="white" pt="6" pb="6">
        <Main>
          <Box textAlign="center">
            &copy; {new Date().getFullYear()} {copyText || 'Healthy Me'}
            {links?.map((item, index) => {
              if (!item.url) {
                console.warn(
                  `Footer link with title:${item.title} has no corresponding url defined`,
                );
              }
              return item.url ? (
                <Fragment key={index}>
                  <Box as="span" px={4}>
                    |
                  </Box>
                  <Link href={item.url} passHref>
                    <ChakraLink>{item.title}</ChakraLink>
                  </Link>
                </Fragment>
              ) : null;
            })}
          </Box>
        </Main>
      </Box>
    </Box>
  );
};
