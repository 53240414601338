import {Box} from '@chakra-ui/layout';
import React from 'react';
import {FooterView} from './FooterView';
import {Spinner, Center} from '@chakra-ui/react';
import {useFooterContent} from '../../api/getFooterContent';
import {useLanguageChange} from '../../utils/useLanguageChange';
import {useCustomerTag} from '../../hooks/useCustomerTag';
import {useTriggerTag} from '../../hooks/useTriggerTag';

interface Props {
  lazyLoading: boolean;
  copyText?: string;
  minimalistic?: boolean;
}

export const FooterController = ({
  lazyLoading,
  copyText,
  minimalistic = false,
}: Props) => {
  const customer = useCustomerTag();
  const trigger = useTriggerTag();
  const config: string[] = [customer, trigger];
  const {data, isLoading, isFetching, refetch} = useFooterContent(config);

  useLanguageChange(refetch);

  return (
    <Box as="footer" bg="footerBackground">
      {isLoading || isFetching ? (
        <Center>
          <Spinner
            mt={10}
            emptyColor="gray.200"
            thickness="4px"
            speed="0.7s"
            size="xl"
            color="primary"
          />
        </Center>
      ) : data ? (
        <FooterView
          lazyLoading={lazyLoading}
          title={data.title}
          subTitle={data.subTitle}
          appUrls={data.appUrls}
          contact={data.contact}
          links={data.links}
          usps={data.usps}
          image={data.image}
          copyText={copyText}
          minimalistic={minimalistic}
        />
      ) : null}
    </Box>
  );
};
