import {
  Box,
  Flex,
  Text,
  Image,
  Select,
  Heading,
  Link as ChakraLink,
} from '@chakra-ui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {languageSelector} from '../../lib/context/selectors';
import {languages} from '../../providers/i18n/i18n';
import {theme} from '../../theme/themes/default';
import {KhsLink} from '../_shared/KhsLink';
import {Main} from '../_shared/Layout/Main';
import Link from 'next/link';
export interface HeaderProps {
  imageURL?: string;
  handleLanguageSelect: (value: string) => void;
  lazyLoading: boolean;
  title: string;
}

export const HeaderView = ({
  imageURL,
  handleLanguageSelect,
  lazyLoading,
  title,
}: HeaderProps) => {
  const {t} = useTranslation();
  const language = useSelector(languageSelector);

  return (
    <Box data-cy="header" py={[4, 8]} bg="headerBackground">
      <Main>
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          flexDirection={['column', null, 'row']}>
          <Flex flexDirection={['column', null, 'row']}>
            {title && (
              <Link href="/" passHref>
                <ChakraLink
                  sx={{
                    _hover: {
                      textDecoration: 'none',
                    },
                  }}>
                  <Heading
                    maxWidth={[352, 352, 262]}
                    marginBottom={['3', '3', '0', '0']}
                    paddingRight="3"
                    as="h1"
                    variant="h4">
                    {title}
                  </Heading>
                </ChakraLink>
              </Link>
            )}
            {imageURL && (
              <Flex alignItems={[null, null, 'center', 'center']}>
                <Link href="/" passHref>
                  <ChakraLink
                    sx={{
                      _hover: {
                        textDecoration: 'none',
                      },
                    }}>
                    <Image
                      data-cy="headerImage"
                      mb={[4, 0]}
                      loading={lazyLoading ? 'lazy' : 'eager'}
                      alt=""
                      src={imageURL}
                      maxHeight="70px"
                    />
                  </ChakraLink>
                </Link>
              </Flex>
            )}
          </Flex>
          <Box>
            <Flex
              flexDirection={['column', null, 'row']}
              alignItems={['flex-end', 'flex-end', 'center']}>
              <Flex alignItems="center">
                <Text mr={1} mb={0} flex={[1, 'auto']}>
                  {t('poweredby')}
                </Text>
                <KhsLink
                  data-cy="headerPoweredbyLink"
                  href="https://www.klmhealthservices.com"
                  title="KLM Health Services"
                  aria-label={t('goToAltText', {url: 'KLM Health Services'})}
                  isExternal
                />
              </Flex>
              <Select
                ms="8"
                width="auto"
                cursor="pointer"
                boxShadow="0px 24px 34px -13px rgba(0, 39, 118, 0.13)"
                iconColor={theme.colors.faqIconColor}
                borderRadius="11px"
                backgroundColor="faqBackground"
                border="none"
                variant="filled"
                style={{marginRight: 10}}
                value={language}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handleLanguageSelect(e.currentTarget.value)
                }
                data-cy="LanguageSelector">
                {languages.map((lng) => (
                  <option key={lng} value={lng}>
                    {lng.toUpperCase()}
                  </option>
                ))}
              </Select>
            </Flex>
          </Box>
        </Flex>
      </Main>
    </Box>
  );
};
