import {
  AddAction,
  AddOrUpdateIntent,
  ChangeAction,
  RemoveAction,
  ResetAction,
  Tag,
  TagChange,
  TagRemove,
} from './types';

export const addTag = (tag: Tag): AddAction => {
  return {
    type: 'ADD_TAG',
    payload: tag,
  };
};
export const addOrUpdateIntent = (tag: Tag): AddOrUpdateIntent => {
  return {
    type: 'ADD_OR_UPDATE_INTENT',
    payload: tag,
  };
};
export const removeTag = (tag: TagRemove): RemoveAction => {
  return {
    type: 'REMOVE_TAG',
    payload: tag,
  };
};
export const changeTag = (tag: TagChange): ChangeAction => {
  return {
    type: 'CHANGE_TAG',
    payload: tag,
  };
};

export const setLanguage = (language: string): AddAction => {
  return {
    type: 'ADD_TAG',
    payload: {
      axis: 'environment',
      label: 'language',
      value: language,
      mutable: true,
      removable: false,
      single: true,
      score: 100,
    },
  };
};

export const setCustomer = (customer: string): ChangeAction => {
  return {
    type: 'CHANGE_TAG',
    payload: {
      axis: 'environment',
      label: 'customer',
      value: customer,
    },
  };
};

export const changeLanguage = (language: string): ChangeAction => {
  return {
    type: 'CHANGE_TAG',
    payload: {
      axis: 'environment',
      label: 'language',
      value: language,
    },
  };
};

export const setCountry = (country: string): AddAction => {
  return {
    type: 'ADD_TAG',
    payload: {
      axis: 'environment',
      label: 'country',
      value: country,
      score: 100,
      single: true,
      mutable: true,
      removable: false,
    },
  };
};

export const reset = (): ResetAction => {
  return {
    type: 'RESET',
  };
};
