import {ArrowRight} from './Icons/ArrowRight';
import {Info} from './Icons/Info';
import {Box} from '@chakra-ui/react';
import {Colors} from '../../../theme/colors';
import {ArrowLeft} from './Icons/ArrowLeft';

enum ICONS {
  ARROW_RIGHT = 'arrow_right',
  ARROW_LEFT = 'arrow_left',
  INFO = 'info',
}

interface Props {
  name: 'arrow_right' | 'arrow_left' | 'info';
  color: keyof Colors;
  width: string;
  height: string;
}

export const Icon = ({name, color, width, height}: Props) => {
  switch (name) {
    case 'arrow_right':
      return <ArrowRight color={color} width={width} height={height} />;
    case 'arrow_left':
      return <ArrowLeft color={color} width={width} height={height} />;
    case 'info':
      return <Info color={color} width={width} height={height} />;
    default:
      return <Info color={color} width={width} height={height} />;
  }
};

export const StoryBookIcons = () => {
  const IconArray = [];
  for (let icon in ICONS) {
    const MyIcon: ICONS = ICONS[icon as keyof typeof ICONS];
    IconArray.push(
      <Box mb={2} key={MyIcon}>
        <Icon name={MyIcon} width="24" height="24" color="primary" />
      </Box>,
    );
  }
  return <>{IconArray}</>;
};
