export interface FapperError extends Error {
  status: number;
  statusText: string;
  url: string;
  response: Response;
}

const handleResponse = async (response: Response) => {
  if (!response.ok) {
    throw createFapperError(
      `Request failed with status code ${response.status}. Status text: ${response.statusText}`,
      response.url,
      response.status,
      response.statusText,
      response,
    );
  }

  return response.json();
};

const FetchWrapper = async (url: RequestInfo, init?: RequestInit) => {
  const response = await fetch(url, init);

  return handleResponse(response);
};

const getData = async (url: RequestInfo, init?: RequestInit) => {
  const response = await fetch(url, {...init, method: 'GET'});

  return handleResponse(response);
};

const postData = async (url = '', data = {}, init?: RequestInit) => {
  const response = await fetch(url, {
    mode: 'cors',
    cache: 'default',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer-when-downgrade',
    ...init,
    method: 'POST',
    body: JSON.stringify(data),
  });

  return handleResponse(response);
};

const createFapperError = (
  message: string,
  url: string,
  status: number,
  statusText: string,
  response: Response,
) => {
  const error = new Error(message) as FapperError;
  error.url = url;
  error.status = status;
  error.statusText = statusText;
  error.response = response;

  return error;
};

FetchWrapper.post = postData;
FetchWrapper.get = getData;

// A fetchwrapper, a.k.a fapper
export const fapper = FetchWrapper;
