import {useSelector} from 'react-redux';
import {contextSelector} from '../lib/context/selectors';

export const useTriggerTag = () => {
  const context = useSelector(contextSelector);

  const trigger = context.filter((c) => {
    return c.axis === 'environment' && c.label === 'trigger';
  })[0];

  if (!trigger) {
    throw new Error('Required trigger not found in context');
  }

  return trigger.value.toString();
};
